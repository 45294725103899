import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from '../../hooks/Nav';
import useFormatDate from '../../hooks/useFormatDate';
import SlideOver from '../../tailwindUI/SlideOver';
import Sort from '../../tailwindUI/Sort';
import Filters from '../../tailwindUI/Filters';
import SearchBar from '../../tailwindUI/SearchBar';
import EmptyState from '../../tailwindUI/EmptyState';
import Table from '../../tailwindUI/Table';
import { AdjustmentsHorizontalIcon, TrashIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Pagination from '../../tailwindUI/Pagination';
import usePagination from '../../tailwindUI/usePagination';
import useSort from '../../tailwindUI/useSort';
import LongAlert from '../../tailwindUI/LongAlert';
import { AlertContext } from '../../context/AlertContext';
import useFilter from '../../tailwindUI/useFilter';
import useScrollPosition from '../../hooks/useScrollPosition';
import Notification from '../../tailwindUI/Notification';
import { AuthContext } from '../../cognito/AuthProvider';
import { getReportCSV, getUpdatedReport } from '../../apiClient/operations/chargesOperations';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import { DocumentArrowDownIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import DateInterval from '../../tailwindUI/DateInterval';
import useDateInterval from '../../tailwindUI/useDateInterval';


const tableColumns = [
    { heading: 'Fecha de Actualización', value: 'formatted_updated', subvalue: 'formatted_updated_hour', align: 'center' },
    { heading: 'Nombre', value: 'account.name', subvalue: 'account.tax_id', main: true, },
    { heading: 'Email', value: 'account.email' },
    { heading: 'Monto', value: 'formatted_amount', align: 'center' },
    { heading: 'Estatus', value: 'status', badge: true, align: 'center' },
    { heading: 'Método de pago', value: 'translated_method', subvalue: 'translated_provider', align: 'center' },
];

function useFirstRender() {
    const firstRender = useRef(true);

    useEffect(() => {
        firstRender.current = false;
    }, []);

    return firstRender.current;
}




function ChargesReport() {
    const { user } = React.useContext(AuthContext);
    const paginate = usePagination();
    const { formatDate } = useFormatDate();
    const { formatterCurrency } = useFormatterCurrency();

    const sortItem = useSort();
    const filterItem = useFilter();
    const dateInterval = useDateInterval();
    const setScrollPosition = useScrollPosition();
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'update_date', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'update_date', sort: 'direct', active: false },
        { id: 3, name: 'Nombre descendente', field: 'account.name', sort: 'reverse', active: false },
        { id: 4, name: 'Nombre ascendente', field: 'account.name', sort: 'direct', active: false }
    ]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            name: 'Estatus',
            value: 'status',
            open: false,
            options: [
                { id: 1, value: 'paid', label: 'Pagado', applied: false, checked: false, filter_id: 1 },
                { id: 2, value: 'failed', label: "Fallido", applied: false, checked: false, filter_id: 1 },
                { id: 3, value: 'chargeback', label: "Contracargo", applied: false, checked: false, filter_id: 1 },
                { id: 4, value: 'refund', label: "Reembolso", applied: false, checked: false, filter_id: 1 },
                { id: 5, value: 'partial_refund', label: "Reembolso parcial", applied: false, checked: false, filter_id: 1 },
                { id: 6, value: 'completed', label: "Completado", applied: false, checked: false, filter_id: 1 },
            ],
        },
        {
            id: 2,
            name: 'Proveedor',
            value: 'provider',
            open: false,
            options: [
                { id: 1, value: 'openpay', label: 'Openpay', applied: false, checked: false, filter_id: 2 },
                { id: 2, value: 'conekta', label: "Conekta", applied: false, checked: false, filter_id: 2 },
                { id: 3, value: 'paypal', label: "Paypal", applied: false, checked: false, filter_id: 2 },
            ],
        },
        {
            id: 3,
            name: 'Metodo de pago',
            value: 'payment_method',
            open: false,
            options: [
                { id: 1, value: 'card|debit|credit', label: 'Tarjeta de crédito/débito', applied: false, checked: false, filter_id: 3 },
                { id: 3, value: 'spei|transfer', label: "Transferencia", applied: false, checked: false, filter_id: 3 },
                { id: 4, value: 'oxxo|cash', label: "Efectivo", applied: false, checked: false, filter_id: 3 },
                { id: 5, value: 'paypal', label: "Paypal", applied: false, checked: false, filter_id: 3 },
            ],
        }
    ]);
    const [intervalsFilter, setIntervalsFilter] = useState(
        {
            id: 1,
            name: 'Fecha de actualización',
            value: 'update_date',
            open: false
        }
    );
    const history = useHistory();
    const [searchInput, setSearchInput] = useState(null);
    const [sortString, setSortString] = useState('');
    const [filterString, setFilterString] = useState('');
    const [openSlide, setOpenSlide] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [currentPage, setCurrenPage] = useState(1);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { alert, setAlert } = useContext(AlertContext);
    const [showAlert, setShowAlert] = useState(false);
    const [reportLoading, setReportLoading] = useState(false);
    const [notification, setNotification] = useState(false);
    var first_render = useFirstRender();

    var payment_methods = {
        'card': 'Tarjeta de crédito/débito',
        'debit': 'Tarjeta de débito',
        "credit": "Tarjeta de crédito",
        "paypal": "Paypal",
        "spei": "Transferencia",
        "transfer": "Transferencia",
        "oxxo": "Efectivo",
        "cash": "Efectivo",
    }
    var payment_providers = {
        'openpay': 'Openpay',
        'conekta': 'Conekta',
        "paypal": "Paypal",
    }
    var paymentTypes = {
        'unique': 'Pago único',
        'reimbursement': "Devolución",
        'subscription': 'Suscripción',
        'deferred': 'Pago diferido'
    }

    const handleView = item => {
        history.push(`taxpayers/view/${item.account.id}#payment-balance`, {
            // history.push(`taxpayers/view/${item.account.account_id}#payment-balance`, {
            charge: item
        }
        )
    }

    const columnActions = [
        {
            id: 1,
            name: 'Ver detalle',
            type: 'primary',
            icon: <DocumentMagnifyingGlassIcon className='w-5 h-5 text-gray-600 lg:text-white' />,
            action: handleView,
        }
    ];

    const generateCSV = async () => {

        setReportLoading(true)
        const user_request = {
            email: user.email
        }
        await getReportCSV(searchInput, filterString, sortString, user_request);
        setAlert({ active: true, type: 'success', message: 'El reporte ha sido enviado a tu correo electrónico' })
        setReportLoading(false)
    }

    const handleGetSubscriptions = async (page) => {
        setIsLoading(true);
        try {
            setScrollPosition(0);
            setCurrenPage(page);
            const res = await getUpdatedReport(page, 10, searchInput, filterString, sortString);
            setPagination(paginate(res.total_items, 10, Math.ceil(res.total_items / res.limit)));
            const response = res.data.map(item => {
                var returnItem = {
                    ...item,
                    account: {
                        ...item.account,
                        tax_id: item?.account?.taxpayer?.tax_id
                    },
                    formatted_paid_at: formatDate(item.payment_date, 'DD/MM/YY HH:mm:ss'),
                    formatted_created_at: formatDate(item.created_at, 'DD/MM/YY HH:mm:ss'),
                    formatted_updated: formatDate(item.update_date, 'DD MMMM YYYY'),
                    formatted_updated_hour: formatDate(item.update_date, 'HH:mm') + " hrs",
                    payment_formated: formatDate(item?.payment_date, 'DD MMMM YYYY'),
                    hour_formated: formatDate(item?.payment_date, 'HH:mm') + " hrs",
                    formatted_amount: formatterCurrency(item.amount / 100),
                    translated_method: payment_methods[item?.payment_method],
                    translated_provider: payment_providers[item?.provider] || item?.provider,
                    translated_type: paymentTypes[item.type]
                }
                if (payment_methods[item?.payment_method_type] == "Paypal") {
                    delete returnItem['translated_method']
                }
                return returnItem
            });
            setSubscriptions(response);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setError(e.message || 'Hubo un error al obtener la información.');
        }
    }

    const handleCleanFilters = () => {
        const newFilters = filters.map(filter => {
            const newOptions = filter.options.map(option => {
                return {
                    ...option,
                    applied: false,
                    checked: false
                }
            });
            return {
                ...filter,
                options: newOptions
            }
        });
        setIntervalsFilter(
            {
                ...intervalsFilter,
                start: null,
                end: null
            }
        );
        setFilters(newFilters);
        setFiltersApplied(false);
        setFilterString('');
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
    }

    const handleSort = item => setSortString(sortItem(item));

    const handlePasteSearchInput = event => setSearchInput(event.clipboardData.getData('text'));

    const handleApplyFilters = () => {
        setFilterString(filterItem(filters) + dateInterval(intervalsFilter));
        setFiltersApplied(false);
    }

    useEffect(() => {
        handleGetSubscriptions(1);
        return () => {
            setSubscriptions([]);
        }
    }, []);

    useEffect(() => {
        if (!first_render) {
            handleGetSubscriptions(1);
            setScrollPosition(0);
            return () => {
                setSubscriptions([]);
            }
        }
    }, [filterString, sortString]);

    useEffect(() => {
        if (!first_render) {
            handleGetSubscriptions(currentPage);
            setScrollPosition(0);
            return () => {
                setSubscriptions([]);
            }
        }
    }, [currentPage]);

    useEffect(() => {
        filtersApplied && handleApplyFilters();
    }, [filtersApplied]);

    useEffect(() => {
        if (!first_render) {
            searchInput !== null && handleGetSubscriptions(1);
            return () => {
                setSubscriptions([]);
            }
        }
    }, [searchInput]);

    useEffect(() => {
        const activeOptions = sortOptions.filter(option => option.active);
        activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);
    return (
        <>
            <div className="w-full">
                <Nav user={user}>
                    <div className="w-full">
                        <span className="text-3xl text-white font-bold">
                            Reporte de cobros
                        </span>
                    </div>
                </Nav>
            </div>
            <div className='min-h-full md:min-h-[calc(100vh-4rem)]'>
                {error &&
                    <div className='w-full sticky top-[68px] lg:top-1 mt-20 lg:mt-4 z-[35]'>
                        <LongAlert title={error} show={error != null} onClose={() => setError(null)} />
                    </div>
                }
                {showAlert &&
                    <div className="w-full sticky top-2 z-[30] mt-0">
                        <LongAlert show={showAlert} onClose={setShowAlert} type={alert.type} title={alert.message} />
                    </div>
                }
                <Notification show={notification} message='Filtros limpiados correctamente' />
                <div className="w-full px-4 pt-4">
                    <div className="hidden lg:flex justify-between items-center w-full">
                        <h1 className="text-4xl font-bold text-left text-gray-900">
                            Reporte de cobros
                        </h1>
                    </div>
                </div>
                <div className={`w-full px-4 py-0 md:py-4 mb-16 lg:mb-8 ${error != null ? 'mt-0' : 'mt-16 lg:mt-0'} `}>
                    {isLoading ?
                        <>
                            <div className='w-full h-12 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                            </div>
                        </>
                        :
                        <>
                            <SearchBar value={searchInput} setValue={setSearchInput} placeholder_mobile='Nombre, correo o RFC' placeholder_desktop='Nombre, correo o RFC' onPaste={handlePasteSearchInput} />
                            <section aria-labelledby="filter-heading" className='pt-4'>
                                <div className="flex items-center justify-between">
                                    <Sort options={sortOptions} title='Ordenar' setOptions={setSortOptions} />
                                    <div className='flex items-center gap-6'>
                                        <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                            Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]' /></span>
                                        </button>
                                        <div className='hidden md:flex'>
                                            <DateInterval filters={intervalsFilter} setFilters={setIntervalsFilter} setFiltersApplied={setFiltersApplied} />
                                        </div>
                                        <Filters filters={filters} setFilters={setFilters} setFiltersApplied={setFiltersApplied} />
                                        {filterString !== '' && <span className='cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900 flex gap-1 pt-0 md:pt-[1px]' onClick={handleCleanFilters}>Limpiar filtros <TrashIcon className='h-5 h-5' /></span>}
                                    </div>
                                </div>
                            </section>
                        </>
                    }
                    {isLoading ?
                        <Table title='Cobros' data={subscriptions} isLoadingData={isLoading} columns={tableColumns} actions={columnActions} />
                        :
                        <>
                            {subscriptions.length > 0 ?
                                <div className='mt-4 space-y-4'>
                                    <Table title='Cobros' data={subscriptions} isLoadingData={isLoading} columns={tableColumns} actions={columnActions} />
                                    <div className='flex flex-col-reverse lg:flex-row justify-between'>
                                        <div className='flex w-full justify-center mt-2 lg:justify-start lg:mt-0'>
                                            <div className='w-full lg:w-auto lg:max-w-sm items-center'>
                                                <div className={`w-full lg:w-auto lg:max-w-sm items-center ${reportLoading && "animate-pulse"}`}>
                                                    <PrimaryButton onClick={!reportLoading && generateCSV} isFullWidth>
                                                        Enviar Reporte {!reportLoading ? <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' /> : <ArrowPathIcon className='w-5 h-5 ml-2 inline-block animate-spin' />}
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </div>
                                        <Pagination pages={pagination?.pages?.length} currentPage={currentPage} setCurrentPage={setCurrenPage} />
                                    </div>
                                </div>
                                :
                                <div className='mt-4'>
                                    <EmptyState title='No se encontraron cobros' text='Para visualizar la información, deben existir primero en el sistema, verifícalo.' />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title='Filtros'>
                <DateInterval filters={intervalsFilter} openSlide={openSlide} setOpenSlide={setOpenSlide} setFilters={setIntervalsFilter} setFiltersApplied={setFiltersApplied} />
                <Filters filters={filters} openSlide={openSlide} setFilters={setFilters} setOpenSlide={setOpenSlide} setFiltersApplied={setFiltersApplied} />

            </SlideOver>
        </>
    )
}

export default ChargesReport;