import { useContext, useEffect, useState } from "react";
import { getConnections } from "../../apiClient/operations/connectionsOperations";
import { updateConnectionConfirmation } from "../../apiClient/operations/selfServiceWorkflowOperations";
import { AuthContext } from "../../cognito/AuthProvider";
import { invalidateConnectionOperation } from "../../apiClient/operations/linksOperations";

export function useConnection({ accountId, initialStatus, connectionId }) {
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);
  const [errorConnection, setErrorConnection] = useState(false);
  const [connection, setConnection] = useState(null);
  const [status, setStatus] = useState(initialStatus);
  const { user } = useContext(AuthContext);

  const confirmedBy = connection
    ? {
        name: connection?.confirmation?.confirmed_by?.name,
        updated: connection?.confirmation?.updated,
        confirmed: connection?.confirmation?.confirmed,
      }
    : null;

  const efirma = connection?.credentials?.type === "efirma" ? connection : null;
  const ciec = connection?.credentials?.type === "ciec" ? connection : null;

  const getSatCredentials = async () => {
    try {
      setIsLoadingConnection(true);
      const res = await getConnections(accountId, connectionId);
      setConnection(res);
    } catch (error) {
      setErrorConnection(error);
    } finally {
      setIsLoadingConnection(false);
    }
  };

  const updateConnectionStatus = async (newStatus) => {
    try {
      if (newStatus === "confirmed" || newStatus === "in_review") {
        await updateConnectionConfirmation(accountId, connectionId, {
          confirmed: newStatus === "confirmed",
          connection_type: "asf",
          confirmed_by: {
            name: user.name,
            email: user.email,
          },
        });
      } else if (newStatus === "invalid") {
        await invalidateConnectionOperation(accountId, connectionId, "asf");
      }
      setStatus(newStatus);
      getSatCredentials();
    } catch (error) {
      setErrorConnection(error);
    }
  };

  useEffect(() => {
    if (accountId && connectionId) {
      getSatCredentials();
    }
  }, [accountId, connectionId]);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  return {
    efirma,
    ciec,
    confirmedBy,
    statusCredentials: status,
    setStatusCredentials: setStatus,
    updateConnectionStatus,
    isLoadingConnection,
    errorConnection,
  };
}
