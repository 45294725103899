import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { formatPeso } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export function TableServicesUniversalLink({
  services,
  removeService,
  changeServiceDiscount,
  changeServiceQty,
  changeServicePrice,
  enabledDisccount,
}) {
  return (
    <div className="w-full overflow-x-scroll pt-4 text-xs font-bold text-v2-gray-title-client">
      <table className="w-full table-auto rounded-t-lg bg-blue-50">
        <thead className="text-gray-700">
          <tr className="text-sm text-center font-semibold">
            <th className="py-4 px-1 pl-2 lg:pl-4 text-left">Servicio</th>
            <th className="px-1">Cantidad</th>
            <th className="px-1">Precio Unitario</th>
            {enabledDisccount && <th className="px-1">Descuento</th>}
            <th className="px-1 pr-2 lg:pr-4">Total</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {services.map((service, index) => (
            <ServiceRow
              key={`${service.id}-${index}`}
              service={service}
              removeService={removeService}
              changeServiceDiscount={changeServiceDiscount}
              changeServiceQty={changeServiceQty}
              changeServicePrice={changeServicePrice}
              enabledDisccount={enabledDisccount}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

const discountList = [
  { type: "direct", symbol: "$" },
  { type: "percent", symbol: "%" },
];

function ServiceRow({
  service,
  removeService,
  changeServiceDiscount,
  changeServiceQty,
  changeServicePrice,
  enabledDisccount,
}) {
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(service.price);
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState(discountList[0].type);
  const total = quantity * service.price;

  const handleQuantityChange = (e) => {
    const newQuantity = e.target.value;

    if (0 < newQuantity || e.target.value.length === 0) {
      setQuantity(newQuantity);
      changeServiceQty(service.id, newQuantity);
    }
  };

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;

    if (Number(newPrice) > 0 || newPrice === "") {
      setPrice(newPrice);
      changeServicePrice(service.id, newPrice);
    }
  };

  const handleDiscount = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");

    if (value === "") {
      setDiscount("");
      changeServiceDiscount(service.id, 0, discountType);
    } else if (
      (discountType === "direct" &&
        parseInt(value) <= service.price * quantity) ||
      (discountType === "percent" && parseInt(value) <= 100)
    ) {
      setDiscount(value);
      changeServiceDiscount(service.id, value, discountType);
    }
  };

  const handleDiscountType = (e) => {
    const value = e.target.value;
    setDiscountType(value);
    setDiscount(0);
    changeServiceDiscount(service.id, 0, value);
  };

  useEffect(() => {}, [discount]);

  return (
    <tr className="font-normal text-gray-900 text-sm text-center capitalize">
      <td className="flex items-center gap-2 py-3 lg:pl-4 px-1 text-left">
        <div className="text-2xl text-v2-input-text">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="cursor-pointer bg-white h-5 w-5"
            onClick={() => removeService(service.id)}
          />
        </div>
        <span>
          {service.name} - {service.legal_type === "enterprise" ? "PM" : "PF"}
        </span>
      </td>
      <td className="px-1">
        <input
          type="text"
          inputMode="number"
          className="font-normal w-20 text-v2-text-bar-steps border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all py-1 rounded-md"
          value={quantity}
          onChange={handleQuantityChange}
          maxLength={5}
        />
      </td>
      <td className="px-1">
        <input
          type="text"
          inputMode="number"
          className="font-normal w-20 text-v2-text-bar-steps border-v2-gray-border-tables focus:ring-v2-blue-text-login focus:border-v2-blue-text-login transition-all py-1 rounded-md"
          value={price}
          onChange={handlePriceChange}
        />
      </td>
      {enabledDisccount && (
        <td className="px-1">
          {/* <div className="flex flex-col"> */}
          <div className="flex">
            <div className="text-base text-v2-text-bar-steps outline-none flex items-center relative">
              {discountType === "direct" && (
                <span className="font-normal block text-v2-gray-title-client absolute left-2">
                  $
                </span>
              )}
              <input
                type="text"
                inputMode="number"
                value={discount}
                onChange={handleDiscount}
                // onChange={(e) => changeServicePriceDiscount(e)}
                className={`font-normal border-v2-gray-border-tables border-r-0 text-v2-text-bar-steps transition-all block w-20 ${
                  discountType != "direct" ? "pl-2" : "pl-6"
                } pr-4 py-1 rounded-l-md`}
              />
            </div>
            <select
              id="disccount_type"
              name="disccount_type"
              className="w-fit rounded-r-md py-0 px-3 border-none bg-v2-blue-text-login text-white"
              onChange={handleDiscountType}
              defaultValue={discountType}
              //   disabled={!selected}
            >
              {discountList.map((disccount_type, i) => (
                <option key={i} value={disccount_type.type}>
                  {disccount_type?.symbol}
                </option>
              ))}
            </select>
          </div>
          {/* {selectedService?.discount_error && (
                <span className="text-error text-xs">
                  {selectedService?.discount_error}
                </span>
              )} */}
          {/* </div> */}
        </td>
      )}
      <td className="px-1 pr-2 lg:pr-4 w-20">
        {enabledDisccount ? (
          <div className="flex flex-col">
            <span className="text-gray-500 ">
              <del>{formatPeso(total)}</del>
            </span>
            <span className="text-gray-900 font-semibold">
              {formatPeso(service.priceWithDiscount)}
            </span>
          </div>
        ) : (
          <span className="text-gray-900 font-semibold">
            {formatPeso(total)}
          </span>
        )}
      </td>
    </tr>
  );
}

TableServicesUniversalLink.propTypes = {
  services: PropTypes.array,
  removeService: PropTypes.func,
  changeServiceDiscount: PropTypes.func,
  changeServiceQty: PropTypes.func,
  changeServicePrice: PropTypes.func,
  enabledDisccount: PropTypes.bool,
};

ServiceRow.propTypes = {
  service: PropTypes.array,
  removeService: PropTypes.func,
  changeServiceDiscount: PropTypes.func,
  changeServiceQty: PropTypes.func,
  changeServicePrice: PropTypes.func,
  setService: PropTypes.func,
  enabledDisccount: PropTypes.bool,
};
